import {React, useState, useEffect} from 'react'
import './home.css'
import Topbar from '../../components/topbar/Topbar'
import { auth } from '../../firebase';
import Axios from 'axios'
import { Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate } from "react-router-dom";

export default function Home(props) {
    const [currentDate, setCurrentDate] = useState(getDate());
    const[email,setEmail] = useState('');
    const[riderMonthAllOrders, setRiderMonthAllOrders] = useState([]);
    const[AllOrders, setAllOrders] = useState([]);
    const[monthDeliveredOrders, setMonthDeliveredOrders] = useState([]);
    const[allDeliveredOrders, setAllDeliveredOrders] = useState([]);
    const[monthCancelledOrders, setMonthCancelledOrders] = useState([]);
    const[allCancelledOrders, setAllCancelledOrders] = useState([]);
    const[monthReturnedOrders, setMonthReturnedOrders] = useState([]);
    const[allReturnedOrders, setAllReturnedOrders] = useState([]);
    const[pendingOrders, setPendingOrders] = useState([]);
    const[thisOrder,setThisOrder] = useState([]);
    const[selectedOrder, setThisSelectedorder] = useState('');
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const[dispatchedOrders,setDispatchedOrders] = useState([]);
    const[transitOrders, setTransitOrders] = useState([])
    const[todayDelivered, setTodayDelivered] = useState([])




    useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getAllMonthRiderOrders?rider='+email).then((res)=>{
            setRiderMonthAllOrders(res.data)
            })
       
      },[email])

    
      
    useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getAllRiderOrders?rider='+email).then((res)=>{
            setAllOrders(res.data)
            })
       
      },[email])


      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getMonthDeliveredRiderOrders?rider='+email).then((res)=>{
            setMonthDeliveredOrders(res.data)
            })
       
      },[email])

      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getAllRiderDeliveredOrders?rider='+email).then((res)=>{
            setAllDeliveredOrders(res.data)
            })
       
      },[email])

      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getMonthCancelledRiderOrders?rider='+email).then((res)=>{
            setMonthCancelledOrders(res.data)
            })
       
      },[email])

      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getAllRiderCancelledOrders?rider='+email).then((res)=>{
            setAllCancelledOrders(res.data)
            })
       
      },[email])
      
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getMonthReturnedRiderOrders?rider='+email).then((res)=>{
            setMonthReturnedOrders(res.data)
            })
       
      },[email])

      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getAllRiderReturnedOrders?rider='+email).then((res)=>{
            setAllReturnedOrders(res.data)
            })
       
      },[email])

      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getRiderPendingOrders?rider='+email).then((res)=>{
            setPendingOrders(res.data)
            })
       
      },[email])
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getRiderDispatchedOrders?rider='+email).then((res)=>{
            setDispatchedOrders(res.data)
            })
       
      },[email])
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getRiderTransitOrders?rider='+email).then((res)=>{
            setTransitOrders(res.data)
            })
       
      },[email])
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getRiderTodayDeliveredOrders?rider='+email+'&startTime='+result).then((res)=>{
            setTodayDelivered(res.data)
            })
       
      },[email])

      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getSelectedOrder?orderNo='+selectedOrder).then((res)=>{
            setThisOrder(res.data)
            })
       
      },[selectedOrder])
      



    useEffect(() =>{
        auth.onAuthStateChanged((user) =>{
          if(user){
              
             setEmail(user.email)
              
          }
         
        })
        
        
       
      },[]) 
      function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${month}/${date}/${year}`;
      }
      let result = Date.parse(currentDate);

      const navigate =  useNavigate();

      const handleClose = () => {

        
        setOpen(false);
        window.location.reload(false);
    }

    const handleClose2 = () => {

        
        setOpen2(false);
        
    }

    const handleOpen = (event) => {
        setThisSelectedorder(event.target.value);
        setOpen(true);
      }

      const handleOpen3 = (event) => {
        setThisSelectedorder(event.target.value);
        setOpen2(true);
      }

      const handleOpen2 = (event) => {
        setThisSelectedorder(event.target.value);
        navigate('/delivery',{state:{id:1,order:event.target.value}});
      }

      const callOrders = () =>{
        navigate('/orders')
      }


  return (
    <div className='homeWrapper'>
        <div className="bigScreen">
            <p>Version only available in mobile screen</p>
        </div>
        <div className="mobileScreen">


        {/* Pending Order Modal */}
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box className='modalStyling'>
            <div className="modalHead">
            <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4 className='modalTitle'>Order Details</h4>
            <hr />

            <div className="orderDetails">
            {thisOrder.map((val) => {
                                      
                var date4 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.assignTime)
                var date3 = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.assignTime)
                 
                  return(

                     <div className="order">
                        <p className='tHeading2'><strong>Order No:</strong> {val.orderNo}</p>
                        <p className='tHeading2'><strong>Store:</strong> {val.store}</p>
                        <p className='tHeading2'><strong>Customer:</strong> {val.customerName}</p>
                        <p className='tHeading2'><strong>Contacts:</strong> {val.customerContact}</p>
                        <p className='tHeading2'><strong>Date:</strong> {date3}</p>
                        <p className='tHeading2'><strong>Time:</strong> {date4}</p>

                        <p className='tHeading2'><strong>Location:</strong> {val.location}</p>
                        <p className='tHeading2'><strong>Landmark:</strong> {val.landmark}</p>
                        <hr />
                        <p className='tHeading2'><strong>Instructions:</strong> {val.specialInstructions}</p>
                        <p className='tHeading2'><strong>Trips:</strong> {val.trips}</p>
                     </div>
                  )
              })}
            </div>
            <hr />

          </Typography>

            </div>
         
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <p>Order has not been dispatched</p>
           <div className="assignButtons">
                {/* <button onClick={submitTransaction} className='addStoreButton'>Initiate Payment</button> */}
                <button className='addStoreButton2' onClick={handleClose}>Close</button>
           </div>
           

            
          </Typography>
        </Box>
      </Modal>

        {/* Pending Order Modal */}
        <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box className='modalStyling'>
            <div className="modalHead">
            <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4 className='modalTitle'>Order Details</h4>
            <hr />

            <div className="orderDetails">
            {thisOrder.map((val) => {
                                      
                var date4 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.assignTime)
                var date3 = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.assignTime)
                 
                  return(

                     <div className="order">
                        <p className='tHeading2'><strong>Order No:</strong> {val.orderNo}</p>
                        <p className='tHeading2'><strong>Store:</strong> {val.store}</p>
                        <p className='tHeading2'><strong>Customer:</strong> {val.customerName}</p>
                        <p className='tHeading2'><strong>Contacts:</strong> {val.customerContact}</p>
                        <p className='tHeading2'><strong>Date:</strong> {date3}</p>
                        <p className='tHeading2'><strong>Time:</strong> {date4}</p>
                        

                        <p className='tHeading2'><strong>Location:</strong> {val.location}</p>
                        <p className='tHeading2'><strong>Landmark:</strong> {val.landmark}</p>
                        <hr />
                        <p className='tHeading2'><strong>Instructions:</strong> {val.specialInstructions}</p>
                        <p className='tHeading2'><strong>Trips:</strong> {val.trips}</p>
                     </div>
                  )
              })}
            </div>
            <hr />

          </Typography>

            </div>
         
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        
           <div className="assignButtons">
                {/* <button onClick={submitTransaction} className='addStoreButton'>Initiate Payment</button> */}
                <button className='addStoreButton2' onClick={handleClose2}>Close</button>
           </div>
           

            
          </Typography>
        </Box>
      </Modal>









            <Topbar />
            <div className="riderStats">
            
                <div className="stats">
                    <div className="statBox" onClick={callOrders}>
                        <p className='boxHeader'>Orders this Month</p>
                        <p className='ordersNumber'>{riderMonthAllOrders.length}</p>
                        <p className='totalOrders'>All Orders: {AllOrders.length}</p>
                    </div>
                    <div className="statBox">
                        <p className='boxHeader' onClick={callOrders}>Delivered this Month</p>
                        <p className='ordersNumber'>{monthDeliveredOrders.length}</p>
                        <p className='totalOrders'>All Delivered Orders: {allDeliveredOrders.length}</p>
                    </div>
                </div>
                <div className="stats">
                    <div className="statBox">
                        <p className='boxHeader' onClick={callOrders}>Cancelled this Month</p>
                        <p className='ordersNumber'>{monthCancelledOrders.length}</p>
                        <p className='totalOrders'>All Cancelled Orders: {allCancelledOrders.length}</p>
                    </div>
                    <div className="statBox">
                        <p className='boxHeader' onClick={callOrders}>Returned this Month</p>
                        <p className='ordersNumber' onClick={callOrders}>{monthReturnedOrders.length}</p>
                        <p className='totalOrders' onClick={callOrders}>All Returned Orders: {allReturnedOrders.length}</p>
                    </div>
                </div>
            </div>
            <div className="ordersBody">
                <div className="activityTitle">{currentDate}</div>
   

         

           <div className="unplannedOrders3">
            <br />
            <p className='unplannedHeading'>Orders in Transit({transitOrders.length})</p>
           
            <div className="table">
                <Table id="table-to-xls" >
                   
                <thead>
                    
                    <tr>
                        
                        <th className='tHeading'>OrderNo</th>
                        <th className='tHeading'>Store</th>
                        <th className='tHeading'>Date</th>
                        <th className='tHeading'>Time Assigned</th>


                       

                        
                        
                    </tr>
                   
                </thead>
               

                <tbody className='heading2'>
                {transitOrders.map((val) => {
                                      
                                        var date4 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.dispatchTime)
                                        var date3 = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.dispatchTime)
                   
                    return(

                        <tr>
                            <button onClick={handleOpen2} className='tbutton'  value={val.orderNo} >{val.orderNo}</button>
                           {/* <td className='tHeading2'>{val.orderNo}</td> */}
                            
                            <td className='tHeading2'>{val.store}</td>
                            <td className='tHeading2'>{date3}</td>
                            <td className='tHeading2'>{date4}</td>
                            

                            
                            
                            
                            {/* <td className='tHeading2'>{date4}</td> */}
                            
                        </tr>
                    )
                })}
               
                </tbody>
            </Table>
            </div>
           </div>



           <div className="unplannedOrders4">
            <br />
            <p className='unplannedHeading'>Delivered Orders Today({todayDelivered.length})</p>
           
            <div className="table">
                <Table id="table-to-xls" >
                   
                <thead>
                    
                    <tr>
                        
                        <th className='tHeading'>OrderNo</th>
                        <th className='tHeading'>Store</th>
                        <th className='tHeading'>Date</th>
                        <th className='tHeading'>Time Assigned</th>


                       

                        
                        
                    </tr>
                   
                </thead>
               

                <tbody className='heading2'>
                {todayDelivered.map((val) => {
                                      
                                        var date4 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.dispatchTime)
                                        var date3 = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.dispatchTime)
                   
                    return(

                        <tr>
                            <button onClick={handleOpen3} className='tbutton'  value={val.orderNo} >{val.orderNo}</button>
                           {/* <td className='tHeading2'>{val.orderNo}</td> */}
                            
                            <td className='tHeading2'>{val.store}</td>
                            <td className='tHeading2'>{date3}</td>
                            <td className='tHeading2'>{date4}</td>
                            

                            
                            
                            
                            {/* <td className='tHeading2'>{date4}</td> */}
                            
                        </tr>
                    )
                })}
               
                </tbody>
            </Table>
            </div>
           </div>

            </div>
        </div>
        

    </div>
  )
}
 