/* eslint-disable no-undef */
import {React, useState,useEffect} from "react";
import './map.css'
import Axios from 'axios'

import {
    Autocomplete,
    useJsApiLoader,
    GoogleMap,
    Marker,
    DirectionsRenderer,LoadScript, StandaloneSearchBox 
  } from '@react-google-maps/api'
import { useRef } from "react";
import {BlueButton} from "../components/BlueButton"
import {DistanceBox} from "../components/DistanceBox";
import { Header } from "../components/Header";
import { useNavigate } from "react-router-dom";




export const Map = (props) =>{


    // const {isLoaded} = useJsApiLoader({
    //     googleMapsApiKey: 'AIzaSyBz5gDJdtUJg9GdmeN2VwNcMMrf-0K1tqQ',
    //     location: ['places']
        
    // })

    

    // useEffect(() =>{
    //     Axios.get('https://api.easybox.ke/api/getStores').then((res)=>{
    //         setStores(res.data)
    //         })
       
    //   },[])

     


    const center = { lat: -1.286389, lng: 36.817223}

    const [Route,setRoute] = useState(null);
    const [distance,setDistance] = useState('');
    const [timetoReach,settimetoReach] = useState('');
    
    const [distanceBox,setDistanceBox] = useState(false);
    const [checkOrder,setCheckOrder] = useState([]);
    const [submitResult,setSubmitResult] = useState('');
    const StartDestination = useRef(null);
    const EndDestination = useRef('');
    const[stores,setStores] = useState([])
    const inputRef = useRef();
    // const[stores,setStores] = useState([]);
    const[orderNo,setOrderNo] = useState("");
    const[selectedStore,setSelectedStore] = useState('');
    const[orderDesc,setOrderDesc] = useState('');
    const[orderValue,setOrderValue] = useState('');
    const[customerName,setCustomerName] = useState('');
    const[customerContact,setCustomerContact] = useState('');
    const[landmark,setLandmark] = useState('');
    const[success,setSuccess] = useState('');
    const[instructions,setInstructions] = useState('');
    const[errorMessage, setErrorMessage] = useState('');
    const placesLibrary = ["places"];
    const [searchResult, setSearchResult] = useState("Result: none");
    const[errorMes,setErrorMess] = useState('')
    const[searchOrder,setSearchOrder] = useState([]);
    const[thisOrder,setThisOrder] = useState('EOUMBMIFM');
    const[positiveSearch,setPositiveSearch] = useState('');
    const[negativeSearch,setNegativeSearch] = useState('');
    // const[actualDistance,setActualDistance] = useState('');
    const[amount,setAmout] = useState('');
    const navigate =  useNavigate();

    // var amount = '';
    var negative = '';
    var actualDistance = '';




    
    const Distance = "Loading";
    // const navigate =  useNavigate();
  
    const postTime = Date.now().toString();
    
    const[selectedPlace,setSelectedPlace] = useState('')

    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyBocgIcE-rvS4tJpT-4JkxIGoTVaWxjDHk',
        libraries: placesLibrary
        
    })

    function checkSuccess(){
      if(searchOrder.length==0){
        // negative = 'Not Found';
        setNegativeSearch('Not Found')
      }
      else{
        // positive= 'Order has been found';
        setPositiveSearch('Found')
      }

    }

    const handleLocationUpdate = () =>{
        const [ place ] = inputRef.current.getPlaces();
            
            // Console the coordinates whenever the location is updated
            if(place){
                console.log(place.formatted_address);
                console.log(place.geometry.location.lat());
                console.log(place.geometry.location.lng());
                setSelectedPlace(place.formatted_address)
            }
        }

    function updateOrder() {        
          Axios.put('https://api.easybox.ke/api/startTrip',{
              
              startTime : Date.now().toString(),
              
              orderNo:props.orderNo
              
          })  
          window.location.reload(false);
        }
  function cancelOrder() {        
      Axios.put('https://api.easybox.ke/api/riderCancelOrder',{
              
            cancelTime : Date.now().toString(),
              
            orderNo:props.orderNo
              
          })
          navigate('/home')
          
        }
  function returnOrder() {        
          Axios.put('https://api.easybox.ke/api/riderReturnOrder',{
              
              returnTime : Date.now().toString(),
              
              orderNo:props.orderNo
              
          })
          navigate('/home')
          
        }



        function Endtrip() {        
          Axios.put('https://api.easybox.ke/api/endTrip',{
              
              endTime : Date.now().toString(),
              
              orderNo:props.orderNo
              
          })
          navigate('/home')
          
        }



    if(!isLoaded){
        return<div> Map is Loading... </div>        
    }

    async function CalculateDistance(){
      // if(StartDestination.current.value === '' || EndDestination.current.value === ''){
      //   return;
      // }
      const GoogleDirection = new google.maps.DirectionsService();
      const Results = await GoogleDirection.route({
        origin: props.start,
        destination: props.loc,
        travelMode: google.maps.TravelMode.DRIVING
      })
      setRoute(Results);
      setDistance(Results.routes[0].legs[0].distance.text)
      settimetoReach(Results.routes[0].legs[0].duration.text);

      
    }

    function onLoad(autocomplete) {
        setSearchResult(autocomplete);
      }
    
      function onPlaceChanged() {
        if (searchResult != null) {
          const place = searchResult.getPlace();
          const name = place.name;
          const status = place.business_status;
          const formattedAddress = place.formatted_address;
          setSelectedPlace(place.formatted_address)
          // console.log(place);
          console.log(`Name: ${name}`);
          console.log(`Business Status: ${status}`);
          console.log(`Formatted Address: ${formattedAddress}`);
        } else {
          alert("Please enter text");
        }
      }
    

   

    return <>
  
    
         <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
           <div className="Content">
             <div className="InputDiv">
               <div className="TextFieldBox">
               
               
                {/* <BlueButton handler={()=>{CalculateDistance(); setDistanceBox(true)}} label={<h3>Get Location</h3>}/> */}
                 </div>
                
              </div>
              <div className="mapCont" >
              <GoogleMap
              
          zoom={15}

          center={center}
          mapContainerClassName="MapBox"
          options={{
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
           fullscreenControl: false,
        }}
        >
  <Marker position={center}/>
  {Route && (
    <DirectionsRenderer directions={Route} />
  )}
</GoogleMap>
<div className="bottomMap">
  <div className="Mbuttons">
  {
                (() => {
                    if(props.status=='Dispatched') {
                            return (
                              <div className="routeButtons">
                              <div className="theButtons">
                                 <button className="locationBtn" onClick={()=>{CalculateDistance(); setDistanceBox(true); updateOrder() }}>Start Trip</button>

                              </div>
                              </div>
                             
                            )
                        } else if (props.status=='Transit') {
                            return (
                              <div className="routeButtons">
                                <div className="theButtons">
                                    <button className="locationBtn" onClick={()=>{CalculateDistance(); setDistanceBox(true);}}>Reload Route</button>
                                    <button className="locationBtn3" onClick={()=>{cancelOrder() }}>Cancel Order</button>

                                </div>
                                <div className="theButtons">
                                <button className="locationBtn4" onClick={()=>{returnOrder() }}>Return</button>
                                 <button className="locationBtn2" onClick={()=>{Endtrip() }}>End Trip</button>

                                </div>
                               
                                 

                              </div>
                             
                            )
                        } 
                })()  
            }  

  </div>
  <div className="mapBX">
  <div className="distanceBox">
              {distanceBox === true && <DistanceBox distance={distance} duration={timetoReach}/>}
            
           
              </div>

  </div>
</div>




{/* <p>Status: {props.status}</p> */}


              </div>
             
              
           </div>


        </div>


    
    </>
};
export default Map;