import {React, useState,useEffect} from 'react'
import './login.css'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Auth, signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../firebase';
import { useNavigate } from "react-router-dom";
export default function Login() {
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [loginError,setLoginError] = useState('');
    const [success,setSuccess] = useState('');
    const [loading,setLoading] = useState('')
  

    const navigate =  useNavigate();


    auth.onAuthStateChanged((user) =>{
      if(user){
          // setLoggedIn(user.email)
          
          navigate('/home')
          
      }
      else{
          navigate('/')
      }
    })


    const signInOption = (e)=>{

        e.preventDefault();
        if(email =='' || password == ''){
            setLoginError('All fields are required');
        }
        else{
        signInWithEmailAndPassword(auth,email,password).then((useCrential)=>{
            navigate('/home')
        }).catch((error)=>{
            setLoading('Loading...')
            setLoginError('Incorrect email/password Combination. Try Again!');
            setLoading('')
            setEmail('');
            setPassword('');
        })
    }
    }
  return (
    <div className='loginWrapper'>
    <div className="loginLeft">
        <img className='loginBanner' src="/assets/easyboxBanner.jpeg" alt="" />

    </div>
    <div className="loginRight">
        <div className="iconDiv">
            <img className='accountIcon' src="/assets/accountIcon.png" alt="" />
        </div>
        <h3 className='loginTitle'>WELCOME BACK! LOGIN TO YOUR ACCOUNT</h3>
        <div className="errorDiv">
            <p>{loading}</p>
            <p style={{color:'red'}}>{loginError}</p>
            <p>{success}</p>
        </div>
        <br />
       <div className="inputDiv">
       <TextField onChange={(e)=> setEmail(e.target.value)} type='email' id="standard-basic" label="Email" variant="standard" />

       </div>
       <br />
       <div className="inputDiv">
       <TextField onChange={(e)=> setPassword(e.target.value)} type='password' id="standard-basic" label="Password" variant="standard" />
       

       </div>
       <div className="forgotDiv">
       <a className='forgot' href="#">Forgot Password?</a>
       </div>
       
       <button onClick={signInOption} className='loginButton'>Login</button>
       

    </div>

    
</div>
  )
}
