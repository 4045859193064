import {React,useState,useEffect} from 'react'
import { Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './orders.css'
import Axios from 'axios'
import { auth } from '../../firebase';
import Topbar from '../../components/topbar/Topbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

export default function Orders() {
    const [myOrders,setMyOrders] = useState([])
    const [email,setEmail] = useState('')
    const [stores,setStores] = useState([])
    const [mall, setMall] = useState('')
    const [date, setDate] = useState('')
    const [date2, setDate2] = useState('');
    const [open2, setOpen2] = useState(false);
    const[thisOrder,setThisOrder] = useState([]);
    const[selectedOrder, setThisSelectedorder] = useState('');
    

    useEffect(() =>{
        auth.onAuthStateChanged((user) =>{
          if(user){
              
             setEmail(user.email)
              
          }
         
        })
        
        
       
      },[]) 
      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getStores').then((res)=>{
            setStores(res.data)
            })
       
      },[])
    useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getAllRiderOrders?rider='+email).then((res)=>{
            setMyOrders(res.data)
            })
       
      },[email])

      useEffect(() =>{
        Axios.get('https://api.easybox.ke/api/getSelectedOrder?orderNo='+selectedOrder).then((res)=>{
            setThisOrder(res.data)
            })
       
      },[selectedOrder])

      const handleOpen3 = (event) => {
        setThisSelectedorder(event.target.value);
        setOpen2(true);
      }

      const handleClose2 = () => {

        
        setOpen2(false);
        
    }

    
  return (
    <div className='ordersWrapper'>
        <div className="bigScreen">
            <p>Version only available in mobile screen</p>
        </div>
        <div className="mobileScreen">
       
        {/* Pending Order Modal */}
        <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box className='modalStyling'>
            <div className="modalHead">
            <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4 className='modalTitle'>Order Details</h4>
            <hr />

            <div className="orderDetails">
            {thisOrder.map((val) => {
                                      
                var date4 = new Intl.DateTimeFormat('en-US',{hour:'2-digit',minute:'2-digit', second:'numeric'}).format(val.assignTime)
                var date3 = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.assignTime)
                 
                  return(

                     <div className="order">
                        <p className='tHeading2'><strong>Order No:</strong> {val.orderNo}</p>
                        <p className='tHeading2'><strong>Store:</strong> {val.store}</p>
                        <p className='tHeading2'><strong>Customer:</strong> {val.customerName}</p>
                        <p className='tHeading2'><strong>Contacts:</strong> {val.customerContact}</p>
                        <p className='tHeading2'><strong>Date:</strong> {date3}</p>
                        <p className='tHeading2'><strong>Time:</strong> {date4}</p>
                        

                        <p className='tHeading2'><strong>Location:</strong> {val.location}</p>
                        <p className='tHeading2'><strong>Landmark:</strong> {val.landmark}</p>
                        <hr />
                        <p className='tHeading2'><strong>Instructions:</strong> {val.specialInstructions}</p>
                        <p className='tHeading2'><strong>Trips:</strong> {val.trips}</p>
                        <p className='tHeading2'><strong>Distance:</strong> {val.distance} KM</p>
                     </div>
                  )
              })}
            </div>
            <hr />

          </Typography>

            </div>
         
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        
           <div className="assignButtons">
                {/* <button onClick={submitTransaction} className='addStoreButton'>Initiate Payment</button> */}
                <button className='addStoreButton2' onClick={handleClose2}>Close</button>
           </div>
           

            
          </Typography>
        </Box>
      </Modal>



            <Topbar />
            <div className="unplannedOrders4">
            <p className='unplannedHeading'>SELECT ORDERS({myOrders.length})</p>
            <div className="calender">
                <label htmlFor="date1">From:</label>
                <input className="inputDate1" id="date1" type='date' onChange={(e)=>{setDate(e.target.value)}} />
                <label htmlFor="date2">To:</label>
                <input className="inputDate1" id="date2" type='date' onChange={(e)=>{setDate2(e.target.value)}} />
                
                



                <div className="table">
        <Table id="table-to-xls" className="table">
                <thead>
                    <tr>
                    <th className='tHeading'>Order#</th>
                        <th className='tHeading'>Date</th>
                        
                        <th className='tHeading'>Outlet</th>
                       
                        
                    </tr>
                </thead>

                <tbody>
                {myOrders.map((val) => {
                     var dte = parseInt(val.assignTime);
                     var d1 = new Date(dte);
                     var total;
                     const timestamp1 = Date.parse(d1);
                     const timestamp2 = Date.parse(date);
                     const timestamp3 = Date.parse(date2)

                     var d= new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(val.assignTime)
                     var d2= new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(val.dispatchTime)
                     var d3= new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(val.deliveryTime)
                    //  d4= new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(row.data.DeliveryTime)
                     var d10= new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(val.postTime)
                     var date3 = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(val.postTime)

                     if(val.assignTime ==null ||val.deliveryTime == null || val.assignTime == null || val.dispatchTime == null ){
                        
                        total = 'Pending'
                    }
                    else{
                        var d5 = (val.deliveryTime) - (val.assignTime)
                        total = new Date(d5).toISOString().slice(11, 19)
                    }
                     
                  

                    if(timestamp1 >= timestamp2 && timestamp1 <= timestamp3){
                        
                       
                                return(
                                    <tr>
                                         <button onClick={handleOpen3} className='tbutton'  value={val.orderNo} >{val.orderNo}</button>
                                        <td className='tHeading3'>{date3}</td>
                                        
                                        <td className='tHeading3'>{val.store}</td>
                                    </tr>
                                    
                                    )
                                    
                                }

                      


                 
                  })}

    
    
            </tbody>
            </Table>
           
        </div>
               
            </div>
            </div>

        </div>

    </div>
  )
}

